<template>
  <div class="content">
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.userAccount") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">
          {{$t("cancel")}}
        </base-button>
        <base-button :disabled="isBtnDisabledDelete" type="danger" @click="deleteItem">
          {{$t("delete")}}
        </base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card class="profileForm mt-5 mb-4">
          <template slot="header">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="card-title">
                  {{ $t(`routes.${this.$route.name}`) }}
                </h4>
                <h1>
                  {{ user.name }}
                </h1>
              </div>
            </div>
          </template>
          <form
            @change="disabledBtn(false)"
            @submit.prevent="manageItem()"
          >
            <div class="d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button type="cancel" @click="$router.push({ path: previousPage })">
                  {{ $t("cancel") }}
                </base-button>
              </div>
              <div>
                <base-button v-if="isCustomer" type="danger" class="mr-2" @click="isModalShow = true">
                  {{ $tc("delete") }}
                </base-button>
                <base-button :disabled="isBtnDisabled" native-type="submit" type="success">
                  {{ $t("save") }}
                </base-button>
              </div>
            </div>
            <hr />
            <div v-if="!isSpinnerShow" class="py-4">
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    max="255"
                    v-model="user.name"
                    :placeholder="$t('name')"
                    :label="`${$t('name')}*`"
                    required
                  ></base-input>
                </div>

              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="email"
                    v-model="user.email"
                    :placeholder="$t('email')"
                    :label="`${$t('email')}*`"
                    required
                  ></base-input>
                </div>
                <div v-if="user.phone || isCustomer" class="col-12 col-md-6">
                  <base-input
                    v-model="user.phone"
                    :placeholder="$t('phone')"
                    :label="`${$t('phone')}*`"
                    required
                  ></base-input>
                </div>
                <div v-if="user.gender || isEmployee" class="col-12 col-md-6">
                  <div>
                    <label class="control-label mt-4 mt-md-0 pb-md-1">
                      {{ $t("gender") }}
                    </label>
                  </div>
                  <base-radio
                    inline
                    name="female"
                    class="mt-0"
                    v-model="user.gender"
                    >{{ $t("female") }}</base-radio
                  >
                  <base-radio
                    inline
                    name="male"
                    class="mt-0"
                    v-model="user.gender"
                    >{{ $t("male") }}</base-radio
                  >
                  <base-radio
                    inline
                    name="diverse"
                    class="mt-0"
                    v-model="user.gender"
                    >{{ $t("diverse") }}</base-radio
                  >
                </div>
                <div class="col-12 col-md-6 pt-3 pt-md-0">
                  <div v-if="isManager">
                    <div>
                      <label>{{ $tc("employee", 1) }}</label>
                    </div>
                    <base-checkbox
                      class="mb-3"
                      :checked="user.is_employee"
                      v-model="user.is_employee"
                    >
                      {{ $t("alsoEmployee") }}
                    </base-checkbox>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
                    $t("picture")
                  }}</label>
                  <div>
                    <image-upload
                      id="inputMainPicture"
                      @change="onImageChange"
                      :src="user.picture"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="profile"
                    />
                  </div>
                </div> -->
              </div>
            </div>
            <Spinner v-if="isSpinnerShow"></Spinner>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card class="profileForm mb-5">
          <template slot="header">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="card-title mb-0">
                  {{ $t(`edit`) }} {{ $t(`password`).toLowerCase() }}
                </h4>
              </div>
            </div>
          </template>
          <form
            @change="disabledBtnPassword(false)"
            @submit.prevent="managePassword()"
          >
            <div class="d-block pb-2 d-sm-flex justify-content-sm-end">
              <base-button :disabled="isBtnDisabledPassword" native-type="submit" type="success">
                {{ $t("save") }}
              </base-button>
              <base-button type="cancel" class="mr-2" @click="$router.push({ path: previousPage })">
                {{ $t("cancel") }}
              </base-button>
            </div>
            <hr />
            <div class="row py-4">
              <div class="col-12 col-md-6">
                <base-input
                  inputType="password"
                  @keydown.space.prevent
                  v-model="user.password"
                  :placeholder="$t('password')"
                  :label="`${$t('password')}*`"
                ></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  inputType="password"
                  @keydown.space.prevent
                  v-model="user.passwordConfirm"
                  :placeholder="$t('passwordConfirm')"
                  :label="`${$t('passwordConfirm')}*`"
                ></base-input>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  // ImageUpload,
  BaseCheckbox,
  Card,
  Modal,
  Spinner,
} from "@/components/index";
import { mapState, mapActions } from "vuex";

export default {
  name: "EditProfile",
  data: () => {
    return {
      route: "/me",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      isBtnDisabledDelete: false,
      user: {
        name: null,
        email: null,
        password: null,
        passwordConfirm: null,
        picture: null,
        gender: null,
        phone: null,
        is_employee: false,
      },
      originalUser: null,
      userPicture: null,
      isModalShow: false,
      isSpinnerShow: true,
    };
  },
  computed: {
    ...mapState([
      "isAdmin",
      "isManager",
      "isEmployee",
      "isCustomer",
      "previousPage",
      "defaultTitle"
    ]),
  },
  methods: {
    createItem() {
      const user = {};
      const keysObject = Object.keys(this.user);

      keysObject.forEach( key => {
        if(this.user[key] !== this.originalUser[key]) user[key] = this.user[key];
      });

      return user;
    },
    createPassword() {
      let password = {
        password: this.user.password,
        password_confirmation: this.user.passwordConfirm,
      };

      if (
        (password.password === null ||
          password.password === "" ||
          password.password === undefined) &&
        (password.password_confirmation === null ||
          password.password_confirmation === "" ||
          password.password_confirmation === undefined)
      ) {
        this.$toast.error(this.$t("notifications.error.emptyPassword"));
        return;
      }

      if (password.password !== password.password_confirmation) {
        this.$toast.error(
          this.$t("notifications.error.passwordConfirmationNotMatch")
        );
        return;
      }
      return password;
    },
    manageItem() {
      const user = this.createItem();
      if (user) this.updateItem(user);
    },
    managePassword() {
      const password = this.createPassword();
      if (password) this.updatePassword(password);
    },
    /* onImageChange(file) {
      this.userPicture = new FormData();
      this.userPicture.append("media", file);
      this.disabledBtn(true);
    }, */
    async getItem() {
      const route = `${this.route}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const user = response.data.data.user; 
        this.user = user; 
        this.originalUser = {...user};
        this.disabledBtn(true);
        this.isSpinnerShow = false;
      }
    },
    async updateItem(user) {
      this.disabledBtn(true);
      const route = `${this.route}`;
      let response = await this.axios.put(route, user);
      if (response && response.data.status === "success") {
        const responseImg = await this.addPicture(route);
        if (responseImg !== undefined && responseImg.data.data.user !== null) this.user = responseImg.data.data.user;
        else await this.getItem();
        this.$store.dispatch("setChangedProfilePicture", this.user.picture);
        localStorage.setItem("profilePicture", this.user.picture);
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async updatePassword(password) {
      this.disabledBtnPassword(true);
      const route = `${this.route}/password`;
      let response = await this.axios.put(route, password);
      if (response && response.data.status === "success") {
        this.user.password = "";
        this.user.passwordConfirm = "";
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteItem() {
      this.disabledBtnDelete(true);
      const route = `${this.route}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        localStorage.removeItem("api_token");
        localStorage.removeItem("user");
        localStorage.removeItem("userRole");
        localStorage.removeItem("profilePicture");
        await this.$router.push({ name: "storeList" });
        this.$toast.success(this.$t("notifications.success.general"));
        this.disabledBtn(false);
      }
    },
    async addPicture(route) {
      if (this.userPicture !== null) {
        const routePicture = `${route}/picture`;
        return await this.axios.post(routePicture, this.userPicture);
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    disabledBtnPassword(disabled) {
      this.isBtnDisabledPassword = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    disabledBtnDelete(disabled) {
      this.isBtnDisabledDelete = disabled;
    },
    ...mapActions(["setChangedProfilePicture", "setHasChangeForm"]),
  },
  beforeMount() {
    this.getItem();
  },
  metaInfo() {
      return { title: `${this.$t('myProfile')} - ${this.defaultTitle}` }
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    // ImageUpload,
    BaseCheckbox,
    Modal,
    Spinner,
  },
};
</script>

<style scoped>
.profileForm {
  margin: auto;
  width: 90vw;
}
</style>
